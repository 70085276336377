import React, { useState, useEffect } from "react";
import Rodal from 'rodal';

import StepsHeader from "./../../components/StepsHeader";

import STEPS from "./../../constants/formSteps";

import FirstStep from "./../../components/FirstStep";
import SecondStep from "./../../components/SecondStep";
import ThirdStep from "./../../components/ThirdStep";
import FourthStep from "./../../components/FourthStep";
import FifthStep from "./../../components/FifthStep";
import Impressum from "./../../components/Impressum";
import Datenschutz from "./../../components/Datenschutz";

import 'rodal/lib/rodal.css';

var pjson = require("./../../../package.json");
const { version } = pjson;

function App() {
  const [currentStep, setCurrentStep] = useState(null);
  const [showImpressum, setShowImpressum] = useState(false);
  const [showDatenschutz, setShowDatenschutz] = useState(false);

  useEffect(() => {
    if (!currentStep) {
      const params = new URLSearchParams(window.location.search);
      const paramsSet = params.get("step");
      if (paramsSet) {
        setCurrentStep(parseInt(paramsSet));
      } else {
        setCurrentStep(1);
      }
    }
  }, [currentStep]);

  useEffect(() => {
    if (currentStep) {
      window.history.pushState("", "", `?step=${currentStep}`);
      window.scrollTo(0, 0);
    }
  }, [currentStep]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (currentStep + 1 <= STEPS.length) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const goToStep = (step: number) => {
    setCurrentStep(step);
  };

  const renderStep = () => {
    const props = {
      currentStep,
      prevStep,
      goToStep,
    };

    switch (currentStep) {
      case 1:
        return <FirstStep {...props} />;
      case 2:
        return <SecondStep {...props} />;
      case 3:
        return <ThirdStep {...props} />;
      case 4:
        return <FourthStep {...props} />;
      case 5:
        return <FifthStep {...props} />;
      default:
        return null;
    }
  };

  const handleImpressum = () => {
    setShowImpressum(true)
  };
  const handleDatenschutz = () => {
    setShowDatenschutz(true)
  }

  return (
    <div className="container">
      <div className="col-12 text-center ">
        <h1>Laubenversicherung</h1>
      </div>

      <div className="mt-4 mb-4">
        <StepsHeader
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
      </div>

      <form onSubmit={handleSubmit}>{renderStep()}</form>

      <div className="col-12">
        <div className="row">
          <div className="col-4 text-left">
            <small>{version}</small>
          </div>
          <div className="col-8 text-right">
            <a href="#impressum" onClick={handleImpressum} title="Impressum">
              <small>Impressum</small>
            </a>
            <span> | </span>
            <a href="#datenschutz" onClick={handleDatenschutz} title="Datenschutz-Hinweise">
              <small>Datenschutz-Hinweise</small>
            </a>
          </div>
        </div>
      </div>
      <Rodal
        animation="slideUp"
        customStyles={{
          maxWidth: 900,
          width: "98vw",
          height: "95vh",
        }}
        visible={showImpressum}
        onClose={() => setShowImpressum(false)}>
        <Impressum />
      </Rodal>
      <Rodal
        animation="slideUp"
        customStyles={{
          maxWidth: 900,
          width: "98vw",
          height: "95vh",
        }}
        visible={showDatenschutz}
        onClose={() => setShowDatenschutz(false)}>
        <Datenschutz />
      </Rodal>
    </div>
  );
}

export default App;
