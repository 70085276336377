import React, { useState, useEffect } from "react";
import CleaveInput from "cleave.js/react";
import Moment from "moment";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faCheck,
  faAt,
} from "@fortawesome/free-solid-svg-icons";

import FormActions from "./../FormActions";
import InfoButton from "./../InfoButton";
import BeraterInfo from "./../BeraterInfo";

// State Hooks
import useFormState from "./../../hooks/useFormState";
import ApiService from "../../services/apiService";
import i18n from "./../../constants/i18n";

import Bootbox from 'bootbox-react';

function FirstStep({ currentStep, prevStep }) {
  const momentToday = Moment(new Date());
  const DATE_FORMAT_DEFAULT = "DD.MM.YYYY";

  const { form, setForm, clear } = useFormState({
    versicherungsbeginn: momentToday.add(1, "days").format(DATE_FORMAT_DEFAULT),
    district: { name: "" },
    districtId: null,
    districtInBB: false,
    colony: { name: "" },
    colonyId: null,
    parzelle: "",
    groesse: "0",
    einverstaendnis_beratungsverzicht: false,
    app: {
      erstinfoDownload: false,
      defBebaut: false,
    },
    vbegin_year: momentToday.add(1, "days").year()
  });
  const [typeahead, setTypeahead] = useState({ isLoading: false, options: [] });
  const [colonies, setColonies]   = useState([]);
  const [showAlert, setShowAlert] = useState(false)

  const startDateInvalid = !Moment(
    form.versicherungsbeginn,
    DATE_FORMAT_DEFAULT
  ).isAfter(Moment(new Date(), DATE_FORMAT_DEFAULT), "day");

  const boxStyles = {
    borderTop: "3px solid #de2920",
    // boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.3)',
  };

  const handleClose = () => {
		console.log("You clicked No!");
		return setShowAlert(false);
	}


  //
  // DISTRICT EFFECT
  useEffect(() => {
    if (form.districtId) {
      getColoniesById(form.districtId);
    }
  }, [form.districtId]);

  //
  // COLONIES EFFECT
  useEffect(() => {
    if (colonies.length && !form.colonyId) {
      setForm({
        ...form,
        colony: colonies[0],
        colonyId: colonies[0].id,
      });
    }
  }, [colonies]);

  useEffect(() => {
    document
      .getElementById("erstinfo")
      .setCustomValidity(
        "Sie müssen die Erstinformationen herunterladen und öffnen!"
      );
  }, []);

  function getColoniesById(districtId) {
    ApiService.getColonies({ district_id: districtId }).then((colonies) =>
      setColonies(colonies)
    );
  }

  function handleDistrictChange(selected) {
    const district = selected[0];
    if (district) {
      setForm({
        ...form,
        districtId: district.id,
        district: district,
        districtInBB: district.in_bbb,
      });
    } else {
      setColonies([]);
      setForm({
        ...form,
        districtId: null,
        district: { name: "" },
        districtInBB: false,
        colony: { name: "" },
        colonyId: null,
      });
    }
  }

  const vm_aktiv = form.vermittlerdaten;

  return (
    <>
      <Bootbox show={showAlert}
        type={"alert"}
        message={"Für das Versicherungsjahr 2025 gibt es Beitragsanpassungen."}
        onClose={handleClose}
      />
      <div className="row p-4 bg-light">
        <div className="col-12 col-md-12 ">
          <div className="form-group">
            <label htmlFor="district">Bezirksverband / Kreisverband</label>
            <AsyncTypeahead
              key={form.district}
              required
              name="district"
              id="district"
              isLoading={typeahead.isLoading}
              labelKey="name"
              selected={form.districtId ? [form.district] : []}
              placeholder="Tippen Sie einen Bezirk..."
              onChange={handleDistrictChange}
              onSearch={(query) => {
                setTypeahead({ ...typeahead, isLoading: true });
                ApiService.getDistricts({ q: query }).then((json) => {
                  setTypeahead({
                    isLoading: false,
                    options: json,
                  });
                });
              }}
              options={typeahead.options}
              emptyLabel="Keine Ergebnisse"
              searchText="Es wird gesucht..."
              promptText="Tippen Sie einen Bezirksnamen..."
            />
            <small>
              <b>Hinweis:</b> Falls Ihr Bezirksverband nicht in der Tabelle
              aufgeführt ist, rufen Sie uns bitte an!
              <br />
              <a
                href={`tel:${i18n.contact.phone_int}`}
                title="Laubenversicherung Agentur Telefonnummer"
              >
                Tel.: {i18n.contact.phone}
              </a>
            </small>
          </div>
        </div>
        <div className="col-12 col-md-12">
          <div className="form-group">
            <label htmlFor="colony">Verein/Kolonie</label>
            <select
              key={form.colonyId}
              style={{ borderRadius: 0 }}
              name="colony"
              id="colony"
              required
              className="form-control"
              disabled={!form.districtId}
              value={form.colonyId || ""}
              onChange={(e) => {
                const selected = colonies.filter(
                  (c) => Number(c.id) === Number(e.target.value)
                )[0];
                setForm({
                  ...form,
                  colonyId: Number(e.target.value),
                  colony: selected,
                });
              }}
            >
              {colonies.map((col) => (
                <option key={col.id} value={col.id}>
                  {col.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-12 col-md-6 col-lg-6">
          <div className="form-group">
            <label htmlFor="parzelle">Parzelle</label>
            <input
              name="parzelle"
              required
              type="text"
              className="form-control"
              value={form.parzelle}
              onChange={(e) => setForm({ ...form, parzelle: e.target.value })}
            />
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-6">
          <div className="form-group">
            <label htmlFor="qm">bebaute Wohn-/ Nutzfläche</label>
            <div className="input-group">
              <input
                name="groesse"
                required
                type="number"
                min="1"
                max="150"
                className="form-control"
                aria-describedby="groesse"
                value={form.groesse}
                onChange={(e) => setForm({ ...form, groesse: e.target.value })}
              />
              <div className="input-group-append">
                <span className="input-group-text" id="groesse">
                  m²
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 bg-light mt-4 p-4" style={boxStyles}>
          <div className="form-group">
            <div className="row">
              <div className="col-12 col-md-4">
                <label htmlFor="versicherungsbeginn">
                  Versicherungsbeginn *
                </label>
                <CleaveInput
                  className="form-control"
                  required
                  name="versicherungsbeginn"
                  onBlur={(e) => {
                    setForm({ ...form,
                              versicherungsbeginn: e.target.value,
                              vbegin_year: Moment(e.target.value, DATE_FORMAT_DEFAULT).year()
                    });

                    if(Moment(e.target.value, DATE_FORMAT_DEFAULT).year() === 2024) {
                      setShowAlert(true)
                    }
                  }}
                  value={form.versicherungsbeginn}
                  placeholder="TT.MM.JJJJ"
                  options={{
                    date: true,
                    delimiter: ".",
                    datePattern: ["d", "m", "Y"],
                  }}
                />
              </div>
              <div className="col-8">
                <br />
                {startDateInvalid && (
                  <div className="alert alert-danger">
                    Der Versicherungsbeginn ist frühestens einen Tag nach
                    Beantragung der Versicherung.
                  </div>
                )}
              </div>
            </div>
            <small>
              <b>Hinweis:</b> Die Hauptfälligkeit ist der 01.01 des Jahres. Bei
              unterjährigem Beginn wird der Beitrag des 1. Jahres nur anteilig
              berechnet.
            </small>
          </div>
        </div>
      </div>

      <div className="col-12 col-md-12 col-lg-12">
        <div className="mt-2">
          <small>
            <b>Hinweis:</b> Bitte die Erstinformation öffnen und herunterladen.
          </small>
        </div>
        <div className="mt-1 mb-4">
          <div className="row">
            <a
              href="/assets/dokumente/Visitenkarte2019.pdf"
              target="_blank"
              onClick={() => {
                setForm({
                  ...form,
                  app: {
                    ...form.app,
                    erstinfoDownload: true,
                  },
                });
              }}
              title="Erstinformation herunterladen"
              style={{ position: "relative", fontSize: "1rem", marginLeft: 15 }}
            >
              {!form.app.erstinfoDownload ? (
                <input
                  type="checkbox"
                  checked={form.app.erstinfoDownload}
                  required
                  id="erstinfo"
                  name="Erstinformationen herunterladen"
                  title="Erstinformationen herunterladen"
                  style={{
                    zIndex: -1,
                    position: "absolute",
                    bottom: 0,
                    margin: "5px",
                  }}
                />
              ) : null}
              <FontAwesomeIcon
                icon={!form.app.erstinfoDownload ? faFilePdf : faCheck}
                style={{ fontSize: 30, marginRight: 15, backgroundColor: '#fff'  }}
              />
              Erstinformation herunterladen
            </a>
            <div style={{ marginLeft: 15 }}>
              <InfoButton message="Dies ist ein Spezialprodukt, welches von der Agentur Voss zur Verfügung gestellt wird. Die Agentur Voss arbeitet mit allen Vertriebspartnern der Feuersozietät Berlin Brandenburg im Rahmen der Laubenversicherung zusammen." />
            </div>
          </div>
        </div>
        <div
          className="custom-inputs"
          key="einverstaendnis_vermittlerinfo_gelesen"
        >
          <input
            className="form-control"
            name="einverstaendnis_vermittlerinfo_gelesen"
            onChange={(e) => {
              setForm({
                ...form,
                ...{ einverstaendnis_vermittlerinfo_gelesen: e.target.checked },
              });
            }}
            required={true}
            type="checkbox"
            checked={form.einverstaendnis_vermittlerinfo_gelesen}
          />
          <label htmlFor="einverstaendnis_vermittlerinfo_gelesen">
            <p className="custom-inputs-label-p">
              Ich bestätige, die Erstinformation für Versicherungsvermittler
              gemäß §15 VersVerm heruntergeladen und gelesen zu haben.
            </p>
          </label>
        </div>

        <small>
          <b>Hinweis: </b>
          Die Richtigkeit des Versicherungswertes wird gewährleistet, wenn der
          Versicherungsnehmer die bebaute Wohn-/Nutzfläche des Gebäudes
          zuzüglich der Grundfläche des Kellers und des Obergeschosses bzw.
          ausgebauten Giebels (ab 1,60 m Raumhöhe) richtig angibt. Die
          Grundfläche der freistehenden Schuppen oder Toiletten sind
          hinzuzurechnen. Veranden und Terrassen müssen nur dann mitberechnet
          werden, wenn sie von drei oder mehr Seiten umbaut sind. Der Ausschluss
          von einzelnen Bauwerken ist nicht möglich. Die Grundflächen der
          einzelnen Gebäude sind zu addieren.
        </small>
        <div className="custom-inputs" key="bestaetigung_bebaute_flaeche">
          <input
            className="form-control"
            name="bestaetigung_bebaute_flaeche"
            onChange={(e) => {
              setForm({
                ...form,
                app: {
                  ...form.app,
                  defBebaut: !form.app.defBebaut,
                },
              });
            }}
            required={true}
            type="checkbox"
            checked={form.app.defBebaut}
          />
          <label htmlFor="bestaetigung_bebaute_flaeche">
            <p className="custom-inputs-label-p">
              Ich bestätige, die Definition bebaute Fläche zur Kenntnis genommen
              zu haben.
            </p>
          </label>
        </div>

        <div className="row mt-4">
          <div className="col-12">
            <div>
              <h3 style={{ color: "#de2920" }}>Sie haben die Wahl</h3>
            </div>
          </div>
          <div
            className="col-md-6 col-sm-12 bg-light"
            style={{
              border: "10px solid white",
            }}
          >
            <div className="col-12 text-center">
              <BeraterInfo />
              {/* <div>
                <FontAwesomeIcon
                  icon={faUserTie}
                  style={{
                    fontSize: "6em",
                    padding: 15,
                    color: "#de2920",
                    textAlign: "center",
                  }}
                />
              </div>
              <a
                href={i18n.beratungsLink}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-secondary"
              >
                Zur Beratersuche
                <FontAwesomeIcon
                  icon={faChevronRight}
                  style={{ marginLeft: 15 }}
                />
              </a>
              <br />
              <br /> */}
            </div>
          </div>
          <div
            className="col-md-6 col-sm-12 bg-light"
            style={{ cursor: "pointer", border: "10px solid white" }}
            onClick={() =>
              setForm({
                ...form,
                ...{
                  einverstaendnis_beratungsverzicht: !form.einverstaendnis_beratungsverzicht,
                },
              })
            }
          >
            <div className="col-12 text-center">
              <FontAwesomeIcon
                icon={faAt}
                style={{
                  fontSize: "6em",
                  padding: 15,
                  color: "#de2920",
                  textAlign: "center",
                }}
              />
            </div>
            <div
              className="custom-inputs bg-light"
              style={{ marginTop: 15 }}
              key="einverstaendnis_beratungsverzicht"
            >
              <input
                className="form-control"
                name="einverstaendnis_beratungsverzicht"
                required={!vm_aktiv}
                onChange={(e) => {
                  setForm({
                    ...form,
                    ...{ einverstaendnis_beratungsverzicht: e.target.checked },
                  });
                }}
                type="checkbox"
                checked={form.einverstaendnis_beratungsverzicht}
              />
              <label htmlFor="einverstaendnis_beratungsverzicht">
                <p className="custom-inputs-label-p">
                  {i18n.legalFirst.einverstaendnis_beratungsverzicht.label}
                </p>
              </label>
            </div>
            {/* <small> */}
            {/*   {i18n.legalFirst.einverstaendnis_beratungsverzicht.desc} */}
            {/* </small> */}
          </div>
          <div className="col-12">
            <p>
              Sie können diese Versicherung online abschließen oder auch telefonisch bei der
               <br />
              <b>
                Generalagentur Matthias Voss der Feuersozietät Berlin
                Brandenburg AG{" "}
              </b>
              <br /> Telefon{" "}
              <a
                href="tel:+493020913790"
                title="Matthias Voss der Feuersozietät Berlin Brandenburg AG - Telefon"
              >
                (030) 20 91 37 90
              </a>
              <br />
              Wenn Sie Online abschließen, verzichten Sie auf eine Beratung. Der
              Verzicht kann sich nachteilig auf die Möglichkeit auswirken,
              Schadenersatzansprüche wegen Verletzung der Beratungs- und
              Dokumentationspflicht geltend zu machen.
            </p>
          </div>
        </div>

        <div className="col-12">
          <div className="row">
            <div className="col-12">
              <div
                key="vermittlerdaten"
                className="float-right"
                style={{ opacity: 0.6 }}
              >
                <input
                  className="form-control no-focus"
                  style={{
                    width: "auto",
                    float: "left",
                    marginRight: 10,
                    outline: "none",
                    border: 0,
                  }}
                  id="vermittlerdaten"
                  name="vermittlerdaten"
                  onChange={(e) => {
                    setForm({
                      ...form,
                      ...{
                        vermittlerdaten: e.target.checked,
                      },
                      ...(!e.target.checked
                        ? {
                            vermittler_name: "",
                            vermittler_nummer: "",
                          }
                        : {}),
                    });
                  }}
                  type="checkbox"
                  checked={form.vermittlerdaten}
                />
                <label
                  htmlFor="vermittlerdaten"
                  style={{
                    fontWeight: "500",
                    color: "gray",
                    paddingTop: 5,
                  }}
                >
                  Nur für Vermittler
                </label>
              </div>
            </div>
          </div>
        </div>

        {form.vermittlerdaten && (
          <>
            <div className="row">
              <div className="col-12 col-md-6 col-lg-6">
                <input
                  required
                  className="form-control"
                  type="text"
                  placeholder="Vermittlername"
                  value={form.vermittler_name}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      ...{ vermittler_name: e.target.value },
                    })
                  }
                />
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <input
                  required
                  className="form-control"
                  type="text"
                  placeholder="Vermittler-Nr."
                  value={form.vermittler_nummer}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      ...{ vermittler_nummer: e.target.value },
                    })
                  }
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-12 col-md-6 col-lg-6">
                <input
                  required
                  className="form-control"
                  type="text"
                  placeholder="Vermittler Telefon"
                  value={form.vermittler_telefon}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      ...{ vermittler_telefon: e.target.value },
                    })
                  }
                />
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <input
                  required
                  className="form-control"
                  type="email"
                  placeholder="Vermittler E-Mail"
                  value={form.vermittler_email}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      ...{ vermittler_email: e.target.value },
                    })
                  }
                />
              </div>
            </div>
          </>
        )}
      </div>

      <FormActions
        clear={() => {
          clear();
          setColonies([]);
          setTypeahead({ isLoading: false, options: [] });
        }}
        currentStep={currentStep}
        enableForwardButton={
          form.colonyId !== null && form.einverstaendnis_vermittlerinfo_gelesen
        }
        prevStep={prevStep}
      />
    </>
  );
}

export default FirstStep;
