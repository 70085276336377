import React, { useEffect } from "react";

import FormActions from "./../FormActions";
import VersicherungenInfo from "./../../components/VersicherungenInfo";
import TotalCosts from "./../TotalCosts";

import useFormState from "./../../hooks/useFormState";
import useTotalCosts from "./../../hooks/useTotalCosts";

import {
  getByDistrict,
  insuranceTypes,
} from "./../../constants/insuranceTypes";

function SecondStep({ currentStep, prevStep }) {
  const { form, setForm, savedForm, clear, getByKey } = useFormState({
    versicherung: "",
  });
  const { summeGebaeude, summeHausrat, recalc, summeGesamt } = useTotalCosts(0);

  useEffect(() => {
    if (getByKey("colonyId") == null) {
      prevStep();
    }
  }, [form]);

  useEffect(() => {
    if (form.versicherung.length) {
      recalc();
    }
  }, [form]);

  function additionalOptions(insuranceType) {
    if (insuranceType.key === "singleInside") {
      return (
        <div>
          <div key="plus_leitungswasser" className="col-12">
            <input
              required
              disabled={form.versicherung !== insuranceType.name}
              type="radio"
              className="small"
              onChange={(e) => {
                setForm({
                  ...form,
                  optionen: {
                    ...(form.optionen ? form.optionen : {}),
                    plus_leitungswasser: false,
                  },
                });
              }}
              name="plus_leitungswasser"
              id="plus_leitungswasser_false"
              checked={
                form.optionen
                  ? !form.optionen.plus_leitungswasser &&
                    form.versicherung === insuranceType.name
                  : false
              }
            />
            <label
              htmlFor="plus_leitungswasser_false"
              style={{ fontWeight: 500 }}
            >
              <p className="custom-inputs-label-p">Feuer, Sturm / Hagel </p>
            </label>
          </div>
          <div key="plus_leitungswasser" className="col-12">
            <input
              required
              type="radio"
              disabled={form.versicherung !== insuranceType.name}
              className="small"
              onChange={(e) => {
                setForm({
                  ...form,
                  optionen: {
                    ...(form.optionen ? form.optionen : {}),
                    plus_leitungswasser: true,
                  },
                });
              }}
              name="plus_leitungswasser"
              id="plus_leitungswasser_true"
              checked={
                form.optionen
                  ? form.optionen.plus_leitungswasser &&
                    form.versicherung === insuranceType.name
                  : false
              }
            />
            <label
              htmlFor="plus_leitungswasser_true"
              style={{ fontWeight: 500 }}
            >
              <p className="custom-inputs-label-p">
                Feuer, Sturm / Hagel und Leitungswasser
              </p>
            </label>
          </div>
        </div>
      );
    }
    return null;
  }

  function renderInsuranceTypes() {
    const insuranceArray = form.districtInBB
      ? getByDistrict.inside
      : getByDistrict.outside;
    return insuranceArray.map((insur, i) => {
      return (
        <div key={insur.key} className="col-12 col-md-6">
          <input
            required
            tabIndex={i}
            type="radio"
            onChange={(e) => {
              setForm({
                ...form,
                optionen: {
                  plus_leitungswasser: false,
                },
                versicherung: insur.name,
              });
            }}
            value={insur.name}
            name="versicherung"
            id={insur.key}
            checked={insur.name === form.versicherung}
          />
          <label htmlFor={insur.key}>
            <p className="custom-inputs-label-p">{insur.title}</p>
          </label>
          {insur.subTitle.length > 0 && (
            <p style={{ marginLeft: 45, fontSize: ".9rem" }}>
              {insur.subTitle}
            </p>
          )}
          {additionalOptions(insur)}
        </div>
      );
    });
  }

  if (!savedForm()) return null;
  let summeGebaeudeTitle = "Versicherungssumme Gebäude";
  
  if (
    (savedForm().district.in_bbb &&
      (savedForm().versicherung === insuranceTypes.kombiPlus.name ||
        savedForm().versicherung === insuranceTypes.singleInside.name)) ||
    (!savedForm().district.in_bbb &&
      (savedForm().versicherung === insuranceTypes.kombiKomfort.name ||
        savedForm().versicherung === insuranceTypes.singleOutside.name))
  ) {
    summeGebaeudeTitle = "ca. " + summeGebaeudeTitle;
  }

  return (
    <>
      <div className="row">
        <div className="col-12 ">
          <VersicherungenInfo inBBB={savedForm().district.in_bbb} />
        </div>
        <div className="col-12 col-lg-12 mb-4">
          <div className="form-group custom-inputs mb-4">
            <div className="row">{renderInsuranceTypes()}</div>
          </div>
        </div>
        <div className="col-12 mt-2 col-lg-6 text-center-small">
          <h6>bebaute Fläche</h6>
          <h4 className="text-muted">{form.groesse} m&sup2;</h4>
        </div>
        <div className="col-12 col-lg-6 text-center-small">
          <TotalCosts value={summeHausrat} title="Versicherungssumme Hausrat" />
        </div>
        <div className="col-12 mt-2 col-lg-6 text-center-small">
          <TotalCosts value={summeGebaeude} title={summeGebaeudeTitle} />
        </div>
        <div className="col-12 mt-2 col-lg-6 text-center-small">
          <TotalCosts
            value={summeGesamt}
            title="Beitrag inkl. Versicherungssteuer"
          />
        </div>
      </div>
      <div className="col-12">
        <FormActions
          clear={clear}
          currentStep={currentStep}
          prevStep={prevStep}
          enableForwardButton={
            form.versicherung ? form.versicherung.length > 0 : false
          }
        />
      </div>
    </>
  );
}

export default SecondStep;
