// @flow
// @format

import React, { useState, useEffect, useCallback } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

import TotalCosts from "./../TotalCosts";

import ApiService from "./../../services/apiService";
import type { FormType } from "./../../FlowTypes.js";

import  Bootbox  from  'bootbox-react';

const values = {
  // plus_elementar_plz: '',
  // plus_elementar_ort: '',
  // plus_elementar_strasse: '',
  plus_elementar_manuell: false,
  plus_elementar_angrenzende_strasse1: "",
  plus_elementar_angrenzende_strasse2: "",
  plus_elementar_angrenzende_strasse3: "",
  plus_elementar_angrenzende_strasse4: ""
  // plus_elementar_mit_vorschaden: false,
  // plus_elementar_anzahl_vorschaeden: 0,
  // plus_elementar_keller: false,
};

const OPTION_TITLES = {
  plus_elementar_plz: "Postleitzahl",
  plus_elementar_ort: "Ort",
  plus_elementar_strasse: "Straße",
  plus_elementar_hausnummer: "Hausnummer",
  plus_elementar_angrenzende_strasse1:
    "Weitere an die KGA angrenzende Straße 1",
  plus_elementar_angrenzende_strasse2:
    "Weitere an die KGA angrenzende Straße 2",
  plus_elementar_angrenzende_strasse3:
    "Weitere an die KGA angrenzende Straße 3",
  plus_elementar_angrenzende_strasse4:
    "Weitere an die KGA angrenzende Straße 4",
  // plus_elementar_mit_vorschaden: 'Vorschäden',
  // plus_elementar_anzahl_vorschaeden: 'Anzahl Vorschäden',
  plus_elementar_keller: "Keller"
};

const OPTION_TYPES = {
  plus_elementar_manuell: "boolean",
  plus_elementar_plz: "text",
  plus_elementar_ort: "text",
  plus_elementar_strasse: "text",
  plus_elementar_hausnummer: "text",
  plus_elementar_angrenzende_strasse1: "text",
  plus_elementar_angrenzende_strasse2: "text",
  plus_elementar_angrenzende_strasse3: "text",
  plus_elementar_angrenzende_strasse4: "text",
  // plus_elementar_mit_vorschaden: 'checkbox',
  // plus_elementar_anzahl_vorschaeden: 'number',
  plus_elementar_keller: "checkbox"
};

type Props = {
  form: FormType,
  setForm: Function,
  summeOptionen: Object,
  handleInputChange: Object => mixed,
  resetElementar: () => mixed,
  toggleVorschaden: () => mixed
};

export default function Elementarschaeden({
  setForm,
  form,
  summeOptionen,
  handleInputChange,
  resetElementar,
  toggleVorschaden,
  ...props
}: Props) {
  const [typeaheadBusy, setTypeaheadBusy] = useState(false);
  const [typeaheadOptions, setTypeaheadOptions] = useState([]);
  const [typeaheadOptionsStrasse, setTypeaheadOptionsStrasse] = useState([]);
  const [typeaheadOptionsHausnummer, setTypeaheadOptionsHausnummer] = useState(
    []
  );
  const [qParams, setQparams] = useState({ gis_plz_name: "" });
  const [plz, setPlz] = useState(null);
  const [ort, setOrt] = useState(null);
  const [strasse, setStrasse] = useState(null);
  const [hausnummer, setHausnummer] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    if (form.optionen.plus_elementar_manuell) {
      return;
    }

    if (form.optionen.plus_elementar_plz.length) {
      ApiService.gisData({
        gis_plz_name: form.optionen.plus_elementar_plz
      }).then(json => {
        const selectedPlz = json.find(
          p => p.plz === form.optionen.plus_elementar_plz
        );
        if(selectedPlz === undefined) {
          return;
        }
        setPlz(selectedPlz);
        ApiService.gisData({
          gis_plz_id: selectedPlz.id
        }).then(json => {
          setTypeaheadOptions(json);

          if (form.optionen.plus_elementar_ort.length) {
            const selectedOrt = json.find(
              o => o.ort === form.optionen.plus_elementar_ort
            );
            setOrt(selectedOrt);
            ApiService.gisData({
              gis_plz_id: selectedPlz.id,
              gis_ort_id: selectedOrt.id
            }).then(json => {
              setTypeaheadOptionsStrasse(json);
              if (form.optionen.plus_elementar_strasse.length) {
                const selectedStrasse = json.find(
                  o => o.strasse === form.optionen.plus_elementar_strasse
                );
                if (!selectedStrasse) return false;
                setStrasse(selectedStrasse);
                ApiService.gisData({
                  gis_plz_id: selectedPlz.id,
                  gis_ort_id: selectedOrt.id,
                  gis_strasse_id: selectedStrasse.id
                }).then(json => {
                  setTypeaheadOptionsHausnummer(json);
                  if (form.optionen.plus_elementar_hausnummer.length) {
                    const selectedHausnumer = json.find(
                      o =>
                        o.hausnummer === form.optionen.plus_elementar_hausnummer
                    );
                    setHausnummer(selectedHausnumer);
                  }
                });
              }
            });
          }
        });
      });
    }
  }, []);

  useEffect(() => {
    if(typeaheadOptions.length === 0 && typeaheadBusy) {
      setShowConfirm(true);
    }
  }, [typeaheadOptions, setTypeaheadOptions]);

  function checkForSingleSelect() {
    if (typeaheadOptions.length === 1 && plz === null) {
      setQparams({
        ...qParams,
        gis_plz_name: typeaheadOptions[0].plz
      });
      setPlz(typeaheadOptions[0]);
      ApiService.gisData({
        gis_plz_id: typeaheadOptions[0].id
      }).then(json => {
        setTypeaheadOptions(json);
      });
      handleInputChange({
        target: {
          value: typeaheadOptions[0].plz,
          name: "plus_elementar_plz"
        }
      });
    }
  }

  const handleYes = () => {
    handleInputChange({
      target: {
        value: true,
        name: "plus_elementar_manuell"
      }
    });

    return setShowConfirm(false);
  }

  const handleNo = () => {
    // handleInputChange({
    //   target: {
    //     value: false,
    //     name: "plus_elementar_manuell"
    //   }
    // });

    return setShowConfirm(false);
  }

  const handleSearch = useCallback((gis_plz_name) => {
    setTypeaheadBusy(true);

    ApiService.gisData({ ...qParams, gis_plz_name }).then(
      json => {
        setTypeaheadOptions(json);
        setTypeaheadBusy(false);
      }
    );
  }, []);

  return (
    <div className="col-12">
      <div className="form-group">
        <div className="row mb-4">
          <div className="col-9">
            <div className="custom-inputs">
              <input
                name="plus_elementar"
                type="checkbox"
                style={{ height: 60 }}
                className="form-control"
                aria-describedby="Einschluss von Elementarschäden"
                checked={form.optionen.plus_elementar}
                onChange={e => handleInputChange(e)}
              />
              <label htmlFor="plus_elementar">
                <p className="custom-inputs-label-p">
                  + Einschluss der weiteren Elementarschäden (Überschwemmung,
                  Rückstau, Erdbeben, Erdfall, Erdrutsch, Schneedruck, Lawinen)
                  an Gebäuden und am Hausrat.
                </p>
              </label>
            </div>
          </div>
          <div className="col-3 text-right">
            <TotalCosts
              value={summeOptionen.plus_elementar}
              textStyle={{ fontSize: "1.2rem" }}
            />
          </div>
        </div>

        {form.optionen.plus_elementar && (
          <div style={{ marginLeft: 20 }}>
            <div className="row">
              <div className="col-12" style={{ fontSize: "0.8em" }}>
                <div
                  style={{
                    padding: 10,
                    backgroundColor: "rgba(200, 200, 200, 0.2)",
                    borderTop: "3px solid #de2920",
                    marginBottom: 15
                  }}
                >
                  <p>
                    Nur für Risikoklasse 1 oder 2 möglich. Selbstbeteiligung 300
                    Euro, darüber hinaus Höchstentschädigung 10000 Euro. Der
                    Versicherungsschutz beginnt mit dem Ablauf von 14 Tagen ab
                    dem Versicherungsbeginn bzw. ab dem Datum der
                    Vertragsänderung (Wartezeit). Soweit vorläufig Deckung
                    erteilt wurde, gilt diese nicht für die weiteren
                    Elementargefahren Überschwemmung, Rückstau, Erdrutsch und
                    Schneedruck.
                  </p>
                  <p>
                    Die Prüfung erfolgt durch die Generalagentur Matthias Voss.
                  </p>
                  <p>
                    <b>
                      Ohne die folgenden Angaben ist der Einschluss von
                      Elementarschäden nicht möglich - Pflichtangaben!
                      <br />
                      Falsch oder fehlende Angaben können den
                      Versicherungsschutz gefährden.
                    </b>
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <div className="row">
                    <div className="col-12">
                      <div className="custom-inputs">
                        <input
                          name="plus_elementar_manuell"
                          type="checkbox"
                          className="form-control"
                          aria-describedby="plus_elementar_manuell"
                          checked={form.optionen.plus_elementar_manuell}
                          onChange={(e) => {
                              handleInputChange({
                                target: {
                                  value: e.target.checked,
                                  name: "plus_elementar_manuell"
                                }
                              });
                            }
                          }
                        />
                        <label htmlFor="plus_elementar_manuell">
                          <p className="custom-inputs-label-p">
                            Ihre Postleitzahl ist nicht vorhanden? Geben Sie die Daten manuell ein
                          </p>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">

                  <label htmlFor="plus_elementar_plz">Postleitzahl</label>
                  {!form.optionen.plus_elementar_manuell ?
                    <>
                      <AsyncTypeahead
                        id="plus_elementar_plz"
                        disabled={showConfirm}
                        inputProps={{ required: true }}
                        key="plus_elementar_plz"
                        name="plus_elementar_plz"
                        isLoading={typeaheadBusy}
                        labelKey="plz"
                        selected={
                          form.optionen.plus_elementar_plz
                            ? [form.optionen.plus_elementar_plz]
                            : []
                        }
                        placeholder="00000"
                        onChange={list => {
                          if (list.length) {
                            setQparams({ ...qParams, gis_plz_name: list[0].plz });
                            setPlz(list[0]);
                            ApiService.gisData({
                              gis_plz_id: list[0].id
                            }).then(json => {
                              setTypeaheadOptions(json);
                            });
                            handleInputChange({
                              target: {
                                value: list[0].plz,
                                name: "plus_elementar_plz"
                              }
                            });
                          } else {
                            setPlz(null);
                            setOrt(null);
                            setHausnummer(null);
                            setStrasse(null);
                            setTypeaheadOptionsStrasse([]);
                            setTypeaheadOptionsHausnummer([]);
                            setTypeaheadOptions([]);
                            resetElementar();
                          }
                        }}
                        onSearch={handleSearch}
                        // onSearch={gis_plz_name => {
                        //   setTypeaheadBusy(true);
                        //   ApiService.gisData({ ...qParams, gis_plz_name }).then(
                        //     json => {
                        //       setTypeaheadOptions(json);
                        //       setTypeaheadBusy(false);
                        //     }
                        //   );
                        // }}
                        onBlur={e => checkForSingleSelect()}
                        onKeyDown={e => {
                          if (e.keyCode === 13) {
                            checkForSingleSelect();
                          }
                          else if (e.keyCode === 8 || e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 46 || e.keyCode === 65) {
                            return
                          }
                          else if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        options={typeaheadOptions}
                        emptyLabel="Keine Ergebnisse"
                        searchText="Es wird gesucht..."
                      />

                      <Bootbox show={showConfirm}
                        message={"Keine Ergebnisse gefunden, wollen Sie die Daten manuell eingeben?"}
                        type={"confirm"}
                        onSuccess={handleYes}
                        onCancel={handleNo}
                        onClose={handleNo}
                        successLabel="Ja, manuell"
                        cancelLabel={"Nein"}
                        successClassNames={"btn-success"}
                      />
                    </>

                    :

                    <input
                      name="plus_elementar_plz"
                      type="text"
                      className="form-control"
                      value={form.optionen.plus_elementar_plz}
                      onKeyDown={e => {
                        if (e.keyCode === 8 || e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 46 || e.keyCode === 65) {
                          return
                        }
                        else if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => handleInputChange({
                          target: {
                            value:  e.target.value,
                            name: "plus_elementar_plz"
                          }
                        }) }
                    />
                  }
                </div>
              </div>
              <div className="col-8">
                <div className="form-group">
                  <label htmlFor="plus_elementar_ort">Ort</label>
                  {!form.optionen.plus_elementar_manuell ?
                    <select
                      disabled={!form.optionen.plus_elementar_plz.length}
                      className="form-control"
                      required
                      value={
                        form.optionen.plus_elementar_ort.length &&
                        typeaheadOptions.length
                          ? typeaheadOptions.find(
                              o => o.ort === form.optionen.plus_elementar_ort
                            ).id
                          : ""
                      }
                      onChange={e => {
                        const selectedOrt = typeaheadOptions.find(
                          o => Number(o.id) === Number(e.target.value)
                        );
                        if (selectedOrt) {
                          setStrasse(null)
                          setHausnummer(null);
                          setTypeaheadOptionsHausnummer([]);
                          setTypeaheadOptionsStrasse([]);

                          ApiService.gisData({
                            gis_plz_id: plz.id,
                            gis_ort_id: selectedOrt.id
                          }).then(json => {
                            setTypeaheadOptionsStrasse(json);
                          });
                          setOrt(selectedOrt);

                          handleInputChange({
                            target: {
                              value: selectedOrt.ort,
                              name: "plus_elementar_ort"
                            }
                          });
                        }
                      }}
                      name="plus_elementar_ort"
                    >
                      {typeaheadOptions.length && (
                        <option value="">-- Bitte auswählen --</option>
                      )}
                      {typeaheadOptions.map(o => (
                        <option key={o.id} value={o.id}>
                          {o.ort}
                        </option>
                      ))}
                    </select>
                  :
                    <input
                      name="plus_elementar_ort"
                      type="text"
                      className="form-control"
                      value={form.optionen.plus_elementar_ort}
                      onChange={(e) => handleInputChange({
                          target: {
                            value:  e.target.value,
                            name: "plus_elementar_ort"
                          }
                        })
                      }
                    />
                  }
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-8">
                <div className="form-group">
                  <label htmlFor="plus_elementar_strasse">Straße</label>
                  {!form.optionen.plus_elementar_manuell ?
                    <select
                      disabled={form.optionen.plus_elementar_ort.length < 1}
                      required
                      className="form-control"
                      value={
                        form.optionen.plus_elementar_strasse.length &&
                        typeaheadOptionsStrasse.length
                          ? typeaheadOptionsStrasse.find(
                              o =>
                                o.strasse === form.optionen.plus_elementar_strasse
                            ).id || ""
                          : ""
                      }
                      onChange={e => {
                        const selectedStrasse = typeaheadOptionsStrasse.find(
                          o => Number(o.id) === Number(e.target.value)
                        );
                        if (selectedStrasse) {
                          setHausnummer(null);
                          setTypeaheadOptionsHausnummer([]);

                          ApiService.gisData({
                            gis_plz_id: plz.id,
                            gis_ort_id: ort.id,
                            gis_strasse_id: selectedStrasse.id
                          }).then(json => {
                            setTypeaheadOptionsHausnummer(json);
                          });
                          setStrasse(selectedStrasse);
                          handleInputChange({
                            target: {
                              value: selectedStrasse.strasse,
                              name: "plus_elementar_strasse"
                            }
                          });
                        }
                      }}
                      name="plus_elementar_strasse"
                    >
                      {typeaheadOptionsStrasse.length && (
                        <option value="">-- Bitte auswählen --</option>
                      )}
                      {typeaheadOptionsStrasse.map(o => (
                        <option key={o.id} value={o.id}>
                          {o.strasse}
                        </option>
                      ))}
                    </select>

                  :

                  <input
                    name="plus_elementar_strasse"
                    type="text"
                    className="form-control"
                    value={form.optionen.plus_elementar_strasse}
                    onChange={(e) => handleInputChange({
                        target: {
                          value:  e.target.value,
                          name: "plus_elementar_strasse"
                        }
                      })
                    }
                  />
                }
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="plus_elementar_hausnummer">Hausnummer</label>
                  {!form.optionen.plus_elementar_manuell ?
                    <select
                      disabled={form.optionen.plus_elementar_strasse.length < 1}
                      className="form-control"
                      required
                      value={
                        form.optionen.plus_elementar_hausnummer.length &&
                        typeaheadOptionsHausnummer.length
                          ? typeaheadOptionsHausnummer.find(
                                o =>
                                  o.hausnummer ===
                                  form.optionen.plus_elementar_hausnummer
                              ) ? typeaheadOptionsHausnummer.find(
                                    o =>
                                      o.hausnummer ===
                                      form.optionen.plus_elementar_hausnummer
                                  ).id : ""
                          : ""
                      }
                      onChange={e => {
                        const selectedHausnumer = typeaheadOptionsHausnummer.find(
                          o => Number(o.id) === Number(e.target.value)
                        );
                        if (selectedHausnumer) {
                          setHausnummer(selectedHausnumer);
                          handleInputChange({
                            target: {
                              value: selectedHausnumer.hausnummer,
                              name: "plus_elementar_hausnummer"
                            }
                          });
                        }
                      }}
                      name="plus_elementar_hausnummer"
                    >
                      {typeaheadOptionsHausnummer.length && (
                        <option value="">-- Bitte auswählen --</option>
                      )}
                      {typeaheadOptionsHausnummer.map(o => (
                        <option key={o.id} value={o.id}>
                          {o.hausnummer}
                        </option>
                      ))}
                    </select>
                  :

                  <input
                    name="plus_elementar_hausnummer"
                    type="text"
                    className="form-control"
                    value={form.optionen.plus_elementar_hausnummer}
                    onChange={(e) => handleInputChange({
                        target: {
                          value:  e.target.value,
                          name: "plus_elementar_hausnummer"
                        }
                      })
                    }
                  />
                }
                </div>
              </div>
            </div>
          </div>
        )}
        {form.optionen.plus_elementar && (
          <div style={{ marginLeft: 20 }}>
            {Object.entries(values).map((ele, i) => {
              if (ele[0] === "plus_elementar_manuell") {
                return null;
              }
              else if (OPTION_TYPES[ele[0]] === "checkbox") {
                return (
                  <div className="custom-inputs" key={ele[0]}>
                    <input
                      name={ele[0]}
                      id={ele[0]}
                      type="checkbox"
                      className="form-control"
                      onChange={e => handleInputChange(e)}
                      aria-describedby={OPTION_TITLES[ele[0]]}
                      checked={form.optionen[ele[0]]}
                    />
                    <label htmlFor={ele[0]}>
                      <p className="custom-inputs-label-p">
                        {OPTION_TITLES[ele[0]]}
                      </p>
                    </label>
                  </div>
                );
              } else {
                return (
                  <div className="form-group" key={ele[0]}>
                    <label htmlFor={ele[0]}>{OPTION_TITLES[ele[0]]}</label>
                    <input
                      autoComplete="new-password"
                      name={ele[0]}
                      id={ele[0]}
                      type={OPTION_TYPES[ele[0]]}
                      className="form-control"
                      onChange={e => handleInputChange(e)}
                      aria-describedby={OPTION_TITLES[ele[0]]}
                      value={form.optionen[ele[0]]}
                    />
                  </div>
                );
              }
            })}
          </div>
        )}

        {form.optionen.plus_elementar && (
          <>
            <div style={{ marginLeft: 20 }}>
              <div className="row">
                <div className="col-12">
                  <h4>Vorschäden</h4>
                </div>
                <div className="col-12">
                  <div className="form-group mb-0">
                    <div className="custom-inputs">
                      <input
                        name="plus_elementar_mit_vorschaden"
                        id="plus_elementar_mit_vorschaden"
                        type="checkbox"
                        className="form-control"
                        onChange={toggleVorschaden}
                        aria-describedby="Vorschaden vorhanden (max. 1 Vorschaden)"
                        checked={
                          form.optionen.plus_elementar_mit_vorschaden &&
                          form.optionen.plus_elementar_anzahl_vorschaeden === 1
                        }
                      />
                      <label htmlFor="plus_elementar_mit_vorschaden">
                        <p className="custom-inputs-label-p">
                          Vorschaden vorhanden (max. 1 Vorschaden)
                        </p>
                      </label>
                    </div>
                  </div>
                  <small>
                    <b>Hinweis:</b> Bei mehr als einem (1) Vorschaden, ist ein
                    Einschluss von Elementarschäden <b>nicht</b> möglich!
                  </small>
                </div>
              </div>
            </div>

            <div style={{ marginLeft: 20 }}>
              <div className="row">
                <div className="col-12">
                  <div className="form-group mt-3 mb-0">
                    <div className="custom-inputs">
                      <input
                        name="plus_elementar_keller"
                        id="plus_elementar_keller"
                        type="checkbox"
                        className="form-control"
                        onChange={e => handleInputChange(e)}
                        aria-describedby="Keller"
                        checked={form.optionen.plus_elementar_keller}
                      />
                      <label htmlFor="plus_elementar_keller">
                        <p className="custom-inputs-label-p">Keller</p>
                      </label>
                    </div>
                  </div>
                </div>
                {form.optionen.plus_elementar_keller && (
                  <div style={{ marginLeft: 20 }}>
                    <div className="col-12">
                      <div className="form-group mb-0">
                        <div className="custom-inputs">
                          <input
                            name="plus_elementar_rueckstausicherung"
                            id="plus_elementar_rueckstausicherung"
                            required
                            type="checkbox"
                            className="form-control"
                            onChange={e => handleInputChange(e)}
                            aria-describedby="Ich bestätige das Vorhandensein einer Rückstausicherung"
                            checked={
                              form.optionen.plus_elementar_rueckstausicherung
                            }
                          />
                          <label htmlFor="plus_elementar_rueckstausicherung">
                            <p className="custom-inputs-label-p">
                              Ich bestätige das Vorhandensein einer
                              Rückstausicherung
                            </p>
                          </label>
                        </div>
                        <small>
                          <b>Hinweis:</b> Ohne Rückstausicherung ist ein
                          Einschluss von Elementarschäden <b>nicht</b> möglich!
                        </small>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
