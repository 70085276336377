import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faCheck } from "@fortawesome/free-solid-svg-icons";

import FormActions from "./../FormActions";
import BeratungsVerzicht from "./../BeratungsVerzicht";
import Loading from "./../Loading";
import TotalCosts from "./../TotalCosts";
import InfoBox from "./../InfoBox";
import EditButton from "./../EditButton";

import { OPTION_NAMES } from "./../../constants/insuranceTypes";

import useFormState from "./../../hooks/useFormState";
import useTotalCount from "./../../hooks/useTotalCosts";

import ApiService from "./../../services/apiService";
import { buildVariants } from "./../../constants/insuranceTypes";

import i18n from "./../../constants/i18n";
import { API_URL } from "./../../services/apiService";
import { connectedValues } from "./../ThirdStep";

let legalI18n = { ...i18n.legal };
//const staticLegal = { ...i18n.legal };

const initialLegalState = {
  einverstaendnis_nicht_in_papierform: false,
  einverstaendnis_auskunft_ueber_website: false,
  einverstaendnis_vermittlerinfo_gelesen: false,
  einverstaendnis_persoenliche_beratung: false,
  beratung_entsprechend_empfehlung: false,
  app: {
    widerruf: false,
    widerrufAccept: false,
    widerrufDownload: false,
    versichererinfoAccept: false,
    versichererinfoDownload: false,
  },
};

const optionColSizes = {
  plus_gewaechshaus: 6,
  plus_gewaechshaus_groesse: 6,
  plus_garage: 6,
  plus_garage_anzahl: 6,

  // NUR KOMBI
  plus_erhoehung: 6,
  plus_erhoehung_vsumme_cents: 6,
  plus_glaskeramik: 6,
  plus_solaranlage: 6,
  plus_solaranlage_vsumme_cents: 6,

  // AUSSERHALB B/BB -> Komfort, Basis, Einzel
  plus_reetdach: 6,
  plus_reetdach_groesse: 6,

  plus_aufraeumungskosten:
    // INNERHALB B/BB> Plus, Einzel IN
    12,

  // IN B/BB -> Einzel IN
  plus_hausrat_einzelversicherung: 6,
  plus_hausrat_einzelversicherung_vsumme_cents: 6,
  plus_glas_einzelversicherung: 6,
  plus_elementar: 12,
  plus_elementar_angrenzende_strasse1: 6,
  plus_elementar_angrenzende_strasse2: 6,
  plus_elementar_angrenzende_strasse3: 6,
  plus_elementar_angrenzende_strasse4: 6,
  plus_elementar_anzahl_vorschaeden: 6,
  plus_elementar_hausnummer: 6,
  plus_elementar_keller: 6,
  plus_elementar_mit_vorschaden: 6,
  plus_elementar_ort: 6,
  plus_elementar_plz: 6,
  plus_elementar_rueckstausicherung: 6,
  plus_elementar_strasse: 6,
  plus_leitungswasser: 6,
};

const checkAdditionalInfo = {
  plus_reetdach:
    "Hinweis: Ein vorhandenes Reetdach muss zwingend mit versichert werden.",
};

function FifthStep({ currentStep, prevStep, goToStep }) {
  const {
    form,
    savedForm,
    setForm,
    getByKey,
    clear,
    clearStorage,
  } = useFormState(initialLegalState);
  const {
    summeOptionen,
    summeGesamt,
    recalc,
  } = useTotalCount(0);
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [downloadToken, setDownloadToken] = useState(null);
  const [bookletName, setBookletName] = useState("");
  const currentFormData = savedForm();

  useEffect(() => {
    recalc();
  }, []);

  useEffect(() => {
    if ((getByKey("colonyId") == null || savedForm() == null) && !done) {
      window.location = window.location.pathname;
    }
    setBookletName(
      currentFormData.district.in_bbb ? "Booklet_BBB" : "Booklet_ABL"
    );
  }, []);

  useEffect(() => {
    if (!done && availableVariants) {
      if (
        Object.entries(availableVariants).length &&
        currentFormData.beratung_entsprechend_empfehlung
      ) {
        setForm({
          ...form,
          ...{
            beratung_entsprechend_empfehlung: false,
          },
        });
      }
    }
  }, [form, done]);

  function finish() {
    const htmlForm = document.getElementsByTagName("form")[0];
    if (!htmlForm) return false;
    if (!htmlForm.checkValidity()) {
      htmlForm.reportValidity();
      return false;
    }

    setLoading(true);
    const finalEntries = savedForm();
    const verzichtParams = {};
    Object.entries(
      finalEntries.district.in_bbb
        ? i18n.beratungsVerzicht.inBBB
        : i18n.beratungsVerzicht.ausserhalbBBB
    ).map((v) => {
      const verzicht = finalEntries[v[0]];
      if (verzicht) {
        verzichtParams[v[0]] = finalEntries[v[0]];
      }
      const grund = finalEntries[v[0] + "_grund"];
      if (grund) {
        verzichtParams[v[0] + "_grund"] = grund;
      }
      return null;
    });

    const params = {
      parzelle: finalEntries.parzelle,
      groesse: finalEntries.groesse,
      district_id: finalEntries.districtId,
      colony_id: finalEntries.colonyId,
      versicherung: finalEntries.versicherung,
      versicherungsbeginn: finalEntries.versicherungsbeginn,
      beratung_anmerkungen: form.beratung_anmerkungen,
      einverstaendnis_nicht_in_papierform:
        form.einverstaendnis_nicht_in_papierform,
      einverstaendnis_auskunft_ueber_website:
        form.einverstaendnis_auskunft_ueber_website,
      einverstaendnis_vermittlerinfo_gelesen:
        form.einverstaendnis_vermittlerinfo_gelesen,
      einverstaendnis_beratungsverzicht: form.einverstaendnis_beratungsverzicht,
      einverstaendnis_persoenliche_beratung:
        form.einverstaendnis_persoenliche_beratung,
      beratung_entsprechend_unserer_empfehlung:
        form.beratung_entsprechend_empfehlung,
      beratung_weitere_versicherungen: form.beratung_weitere_versicherungen,
      ...finalEntries.optionen,
      ...finalEntries.personal,
      ...verzichtParams,
      ...(form.vermittlerdaten
        ? {
            vermittler_name: form.vermittler_name,
            vermittler_nummer: form.vermittler_nummer,
            vermittler_email: form.vermittler_email,
            vermittler_telefon: form.vermittler_telefon,
          }
        : {}),
      ...(form.personal.vorschaeden_anzahl && {
        vorschaeden_anzahl: form.personal.vorschaeden_anzahl,
        vorschaeden_jahr: form.personal.vorschaeden_jahr,
        vorschaeden_schadenarten: form.personal.vorschaeden_schadenarten,
        vorschaeden_summe_schadenhoehe:
          form.personal.vorschaeden_summe_schadenhoehe,
      }),
      ...(form.personal.vorversicherung_name && {
        vorversicherung_kuendigung_von:
          form.personal.vorversicherung_kuendigung_von,
        vorversicherung_kuendigung_zum_date:
          form.personal.vorversicherung_kuendigung_zum_date,
        vorversicherung_name: form.personal.vorversicherung_name,
        vorversicherung_versicherungsnummer:
          form.personal.vorversicherung_versicherungsnummer,
      }),
    };

    ApiService.antragCreate(params).then((resp) => {
      if (resp.status === 200 || resp.status === 201) {
        clearStorage();
        setDone(true);
        setDownloadToken(resp.body.token);
      } else {
        const { message } = resp.body;
        setDone(false);
        setDownloadToken(null);
        setError(
          message ||
            `Ein unbkeannter Fehler ist aufgetreten: ${resp.status} Bitte versuchen Sie es erneut.`
        );
      }
      setLoading(false);
    });
  }

  if (loading) {
    return <Loading />;
  }

  if ((getByKey("colonyId") == null || savedForm() == null) && !done) {
    return null;
  }

  if (!loading && done) {
    return (
      <div className="m-4 text-center">
        <div className="jumbotron">
          <h2 className="display-4">Ihr Antrag wurde erfolgreich gesendet.</h2>
          <hr className="my-4" />
          <h4>
            Bitte öffnen und drucken Sie Ihren Antrag und die Bedingungen
            (Booklet).
          </h4>
          <div>
            <a
              href={`${API_URL}/download_pdf?token=${downloadToken}`}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-primary btn-lg"
            >
              <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: 15 }} />
              Antrag anzeigen
            </a>
          </div>
          <div className="mt-2">
            <a
              href={`/assets/dokumente/${bookletName}.pdf`}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-secondary btn-sm"
            >
              <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: 15 }} />
              Bedingungen (Booklet) anzeigen
            </a>
          </div>
        </div>
      </div>
    );
  }

  const {
    anrede,
    titel,
    beruf,
    beruf_anmerkung,
    vorname,
    nachname,
    geburtsdatum,
    str,
    hsn,
    plz,
    ort,
    telefon,
    email,
    bank,
    iban,
    bic,
  } = currentFormData.personal;

  const {
    groesse,
    parzelle,
    versicherung,
    versicherungsbeginn,
    district,
    colony,
  } = currentFormData;

  const selectedInsuranceOptions = {};
  const selectedConnectValues = {};

  
  Object.entries(currentFormData.optionen).map((op) => {
    // if (op[1] === true || (typeof op[1] === 'string' && op[1].length > 1)) {
    //   selectedInsuranceOptions[op[0]] = op[1];
    // }
    if (
      ((typeof op[1] === "number" && op[1] > 0) ||
        (typeof op[1] === "boolean" && op[1]) ||
        (typeof op[1] === "string" &&
          op[1].length &&
          typeof op[1] === "string" &&
          op[1] !== "0")) &&
      !["plus_elementar_anzahl_vorschaeden"].includes(op[0])
    ) {
      selectedInsuranceOptions[op[0]] = op[1];
    }
    return true;
  });

  Object.entries(selectedInsuranceOptions).map((op) => {
    if (connectedValues[op[0]]) {
      delete selectedInsuranceOptions[op[0]];
      delete selectedInsuranceOptions[connectedValues[op[0]]];
      selectedConnectValues[op[0]] = op[1];
    }
    return null;
  });

  let summeOptionenGesamt = 0;
  Object.entries(summeOptionen).map((s) => {
    summeOptionenGesamt += s[1];
    return true;
  });

  function checkLegal() {
    if (
      form.einverstaendnis_nicht_in_papierform &&
      form.einverstaendnis_auskunft_ueber_website &&
      form.app.widerrufAccept &&
      form.app.widerruf
    ) {
      return true;
    }
    return false;
  }

  // if (form.einverstaendnis_beratungsverzicht) {
  //   legalI18n.einverstaendnis_persoenliche_beratung.hide = true;
  // } else {
  //   legalI18n.einverstaendnis_persoenliche_beratung.hide = false;
  // }
  // if (form.einverstaendnis_persoenliche_beratung) {
  //   legalI18n.einverstaendnis_beratungsverzicht.hide = true;
  // } else {
  //   legalI18n.einverstaendnis_beratungsverzicht.hide = false;
  // }

  const availableVariants = buildVariants(
    currentFormData,
    currentFormData.district.in_bbb
      ? i18n.beratungsVerzicht.inBBB
      : i18n.beratungsVerzicht.ausserhalbBBB
  );

  function versicherungsInfoUrl() {
      if(form.einverstaendnis_beratungsverzicht) {
        return form.districtInBB ?
          "/assets/dokumente/verzicht_versicherungsinformation_in_bb.pdf" :
          "/assets/dokumente/verzicht_versicherungsinformation.pdf";
      } else {
        return form.districtInBB ?
          "/assets/dokumente/versicherungsinformation_in_bb.pdf" :
          "/assets/dokumente/versicherungsinformation.pdf";
       }
  }

  return (
    <>
      <h4 className="mb-4">Übersicht Ihrer Angaben</h4>
      {error.length > 0 && (
        <div className="row">
          <div className="col-9">
            <div className="alert alert-danger">{error}</div>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-12 p-4 bg-light">
          <div className="row">
            <div className="col-9">
              <h5 className="mb-4">Persönliche Daten</h5>
            </div>
            <div className="col-3">
              <EditButton
                label="Bearbeiten"
                iconType="edit"
                onClick={() => goToStep(4)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              <div>
                <b>Name</b>
              </div>
              {anrede} {titel} {vorname} {nachname}
            </div>
            <div className="col-12 col-md-6">
              <div>
                <b>Adresse</b>
              </div>
              <address>
                {str} {hsn}, {ort} {plz}
              </address>
            </div>
            <div className="col-12 col-md-6">
              <div>
                <b>Geburtsdatum</b>
              </div>
              <address>{geburtsdatum}</address>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              <div>
                <b>Telefon</b>
              </div>
              {telefon}
            </div>
            <div className="col-12 col-md-6">
              <div>
                <b>E-Mail</b>
              </div>
              {email}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 col-md-6">
              <div>
                <b>Beruf</b>
              </div>
              {beruf}{" "}
              {beruf_anmerkung
                ? beruf_anmerkung.length > 0
                  ? `(${beruf_anmerkung})`
                  : ""
                : ""}
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12 p-4 bg-light">
          <div className="row">
            <div className="col-9">
              <h5 className="mb-4">Bankdaten</h5>
            </div>
            <div className="col-3">
              <EditButton
                label="Bearbeiten"
                iconType="edit"
                onClick={() => goToStep(4)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-6">
              <div>
                <b>Name der Bank</b>
              </div>
              {bank}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12 col-md-6">
              <div>
                <b>IBAN</b>
              </div>
              {iban}
            </div>
            <div className="col-12 col-md-6">
              <div>
                <b>BIC</b>
              </div>
              {bic}
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12 p-4 bg-light">
          <div className="row">
            <div className="col-9">
              <h5 className="mb-4">Versicherungsdaten</h5>
            </div>
            <div className="col-3">
              <EditButton
                label="Bearbeiten"
                iconType="edit"
                onClick={() => goToStep(1)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-6">
              <div>
                <b>Versicherung</b>
              </div>
              {versicherung}
            </div>
            <div className="col-12 col-md-6 border border-success">
              <div>
                <b>Versicherungsbeginn</b>
              </div>
              {versicherungsbeginn}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12 col-md-6">
              <div>
                <b>Kreis</b>
              </div>
              {district.name}
            </div>
            <div className="col-12 col-md-6">
              <div>
                <b>Kolonie</b>
              </div>
              {colony.name}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12 col-md-6">
              <div>
                <b>Parzelle</b>
              </div>
              {parzelle}
            </div>
            <div className="col-12 col-md-6">
              <div>
                <b>Größe (qm)</b>
              </div>
              {groesse}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12">
              <div className="row">
                <div className="col-9">
                  <h5 className="mb-2">Zusatzoptionen</h5>
                </div>
                <div className="col-3">
                  <EditButton
                    label="Bearbeiten"
                    iconType="edit"
                    onClick={() => goToStep(3)}
                  />
                </div>
              </div>
              <br />
              {Object.entries(selectedConnectValues).map((selectedOption) => {
                return (
                  <InfoBox key={selectedOption[0]}>
                    <div className="row">
                      <div
                        className={`col-${optionColSizes[selectedOption[0]]}`}
                      >
                        <div style={{ fontSize: 13 }}>
                          <b>{OPTION_NAMES[selectedOption[0]].replace(" *", "")}</b>
                        </div>
                        Ja
                      </div>
                      <div
                        className={`col-${optionColSizes[selectedOption[0]]}`}
                      >
                        <div style={{ fontSize: 13 }}>
                          <b>
                            {OPTION_NAMES[connectedValues[selectedOption[0]]]}
                          </b>
                          <div>
                            {
                              typeof currentFormData.optionen[connectedValues[selectedOption[0]]] === "string"
                                ? currentFormData.optionen[connectedValues[selectedOption[0]]]
                                : "Ja"
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <small>{checkAdditionalInfo[selectedOption[0]]}</small>
                  </InfoBox>
                );
              })}

              <div className="row">
                {Object.entries(selectedInsuranceOptions).map(
                  (selectedOption) => {
                    if(selectedOption[0] === "plus_elementar_manuell") {
                      return null;
                    } else if (form.vbegin_year <= 2024 && currentFormData.versicherung === "Einzelversicherung in B/BB" && selectedOption[0] === "plus_leitungswasser") {
                      return null;
                    }
                    return (
                      <div
                        style={
                          {
                            // padding: 10,
                            // borderBottom: '1px solid gray',
                            // marginTop: 5,
                          }
                        }
                        key={selectedOption[0]}
                        className={`col-${optionColSizes[selectedOption[0]]}`}
                      >
                        <InfoBox>
                          <div style={{ fontSize: 13 }}>
                            <b>{OPTION_NAMES[selectedOption[0]]}</b>
                            <div>{checkAdditionalInfo[selectedOption[0]]}</div>
                          </div>
                          {typeof selectedOption[1] === "string"
                            ? selectedOption[1]
                            : "Ja"}
                        </InfoBox>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {!currentFormData.versicherung.includes("Kombi-2000-Plus") && (
        <div className="row mt-4">
          <div className="col-12 p-4 bg-light">
            <div className="row">
              <div className="col-12 col-md-8">
                <h5>Jahresbeitrag Gebäudeversicherung:</h5>
              </div>
              <div className="col-12 col-md-4 text-right">
                <h5>
                  <TotalCosts
                    value={summeGesamt - summeOptionenGesamt}
                    numberOnly
                  />{" "}
                  €
                </h5>
              </div>
              <div className="col-12 col-md-8">
                <h5>Jahresbeitrag Zusatzversicherung:</h5>
              </div>
              <div className="col-12 col-md-4 text-right">
                <h5>
                  <TotalCosts value={summeOptionenGesamt} numberOnly /> €
                </h5>
              </div>
              <div className="col-12 ">
                <hr />
              </div>
              <div className="col-12 col-md-8">
                <h5>Gesamt-Jahresbeitrag</h5>
              </div>
              <div className="col-12 col-md-4 text-right">
                <h5>
                  <TotalCosts value={summeGesamt} numberOnly /> €
                </h5>
              </div>
            </div>
          </div>
        </div>
      )}

      {currentFormData.versicherung.includes("Kombi-2000-Plus") && (
        <div className="row mt-4">
          <div className="col-12 p-4 bg-light">
            <div className="row">
              <div className="col-12 col-md-8">
                <h5>Jahresbeitrag Kombi-2000-Plus:</h5>
              </div>
              <div className="col-12 col-md-4 text-right">
                <h5>
                  <TotalCosts
                    value={summeGesamt - summeOptionenGesamt}
                    numberOnly
                  />{" "}
                  €
                </h5>
              </div>
              <div className="col-12 col-md-8">
                <h5>Jahresbeitrag Zusatzversicherung:</h5>
              </div>
              <div className="col-12 col-md-4 text-right">
                <h5>
                  <TotalCosts value={summeOptionenGesamt} numberOnly /> €
                </h5>
              </div>
              <div className="col-12 ">
                <hr />
              </div>
              <div className="col-12 col-md-8">
                <h5>Gesamt-Jahresbeitrag</h5>
              </div>
              <div className="col-12 col-md-4 text-right">
                <h5>
                  <TotalCosts value={summeGesamt} numberOnly /> €
                </h5>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="row mt-1">
        <div className="col-12">
          <small>
            <b>Hinweis:</b> Die Hauptfälligkeit ist der 01.01. des Jahres. Bei
            unterjährigem Beginn wird der Beitrag des 1. Jahres nur anteilig
            berechnet.
          </small>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12 p-4 bg-light">
          <div className="row">
            <div className="col-12">
              <h5 className="mb-4">Einverständniserklärung</h5>
            </div>
            <div className="col-12">
              {Object.entries(legalI18n).map((legal, i) => {
                if (legal[1].hide) return null;
                return (
                  <div className="custom-inputs" key={legal[0]}>
                    <p
                      className="text-muted"
                      style={{ maginBottom: 0, paddingBottom: 0 }}
                    >
                      {legalI18n[legal[0]].title}
                    </p>
                    <input
                      className="form-control"
                      name={legal[0]}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          ...{
                            [legal[0]]: e.target.checked,
                          },
                        });
                      }}
                      required={
                        legal[0] !== "einverstaendnis_persoenliche_beratung"
                      }
                      type="checkbox"
                      checked={form[legal[0]]}
                    />
                    <label htmlFor={legal[0]}>
                      <p className="custom-inputs-label-p">
                        {legalI18n[legal[0]].label}
                      </p>
                    </label>
                    {legalI18n[legal[0]].desc && (
                      <p style={{ lineHeight: 1 }}>
                        <small
                          dangerouslySetInnerHTML={{
                            __html: legalI18n[legal[0]].desc || "",
                          }}
                        />
                      </p>
                    )}
                    {legal[0] === "einverstaendnis_auskunft_ueber_website" && (
                      <div>
                        <hr />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>

            {!form.einverstaendnis_beratungsverzicht && (
              <div style={{ marginLeft: 15 }}>
                <h5 className="mb-4">Beratung und Empfehlung *</h5>
                {!Object.entries(availableVariants).length && (
                  <div className="col-12">
                    <div className="custom-inputs" key="vermittlerdaten">
                      <input
                        className="form-control"
                        name="beratung_entsprechend_empfehlung"
                        onChange={(e) => {
                          setForm({
                            ...form,
                            ...{
                              beratung_entsprechend_empfehlung:
                                e.target.checked,
                            },
                          });
                        }}
                        type="checkbox"
                        checked={form.beratung_entsprechend_empfehlung}
                      />
                      <label htmlFor="vermittlerdaten">
                        <p className="custom-inputs-label-p">
                          Entsprechend unserer Empfehlung zum Kundenwunsch
                        </p>
                      </label>
                    </div>
                  </div>
                )}
                {!form.beratung_entsprechend_empfehlung && (
                  <BeratungsVerzicht
                    setForm={setForm}
                    form={form}
                    availableVariants={availableVariants}
                  />
                )}
                <div className="col-12 mt-2 mb-2">
                  <label
                    htmlFor="beratung_anmerkungen"
                    style={{ marginBottom: 10 }}
                  >
                    Anmerkung zur Beratung:
                  </label>
                  <input
                    className="form-control"
                    name="beratung_anmerkungen"
                    onChange={(e) => {
                      setForm({
                        ...form,
                        ...{
                          beratung_anmerkungen: e.target.value,
                        },
                      });
                    }}
                    type="text"
                    value={form.beratung_anmerkungen}
                  />
                </div>

                <div className="col-12">
                  <label
                    htmlFor="beratung_weitere_versicherungen"
                    style={{ marginBottom: 10, marginTop: 15 }}
                  >
                    Auf eigenen Wunsch, wurde zu folgenden Versicherungen
                    beraten:
                  </label>
                  <input
                    className="form-control"
                    name="beratung_weitere_versicherungen"
                    onChange={(e) => {
                      setForm({
                        ...form,
                        ...{
                          beratung_weitere_versicherungen: e.target.value,
                        },
                      });
                    }}
                    type="text"
                    value={form.beratung_weitere_versicherungen}
                  />
                </div>
                <hr />
              </div>
            )}
            <div className="col-12">
              <h5 className="primary">Informationen zum Widerrufsrecht</h5>
              <p className="text-muted">
                Bitte laden Sie das PDF-Dokument herunter. Mit dem Anklicken des
                Kästchens bestätigen Sie, dass Sie die "Information zum
                Widerrufsrecht" zur Kenntnis genommen haben.
              </p>
              <a
                href="/assets/dokumente/Widerrufsbelehrung.pdf"
                target="_blank"
                onClick={() => {
                  setForm({
                    ...form,
                    app: {
                      ...form.app,
                      widerrufDownload: true,
                    },
                  });
                }}
                title="Informationen zum Widerrufsrecht herunterladen"
                style={{ fontSize: ".9rem" }}
                className={`btn btn-${
                  !form.app.widerrufDownload ? "primary" : "default"
                } mb-4`}
              >
                <FontAwesomeIcon
                  icon={!form.app.widerrufDownload ? faFilePdf : faCheck}
                  style={{ marginRight: 15 }}
                />
                Informationen zum Widerrufsrecht herunterladen
              </a>

              <div className="custom-inputs" key="widerruf_accept">
                <input
                  className="form-control"
                  name="widerruf_accept"
                  title="Informationen zum Widerrufsrecht akzeptieren"
                  onChange={(e) => {
                    setForm({
                      ...form,
                      app: {
                        ...form.app,
                        widerrufAccept: !form.app.widerrufAccept,
                      },
                    });
                  }}
                  required
                  disabled={!form.app.widerrufDownload}
                  type="checkbox"
                  checked={form.app.widerrufAccept}
                />
                <label htmlFor="widerruf_accept">
                  <p className="custom-inputs-label-p">Akzeptieren *</p>
                </label>
                <div
                  style={{
                    marginTop: -15,
                    marginBottom: 10,
                  }}
                >
                  <small>
                    Sie können erst Akzeptieren, wenn Sie die Informationen zum
                    Widerrufsrecht heruntergeladen.
                  </small>
                </div>
              </div>

              <div className="custom-inputs" key="widerrufsbelehrung">
                <input
                  className="form-control"
                  name="widerrufsbelehrung"
                  title="Informationen zum Widerrufsrecht"
                  onChange={(e) => {
                    setForm({
                      ...form,
                      app: {
                        ...form.app,
                        widerruf: !form.app.widerruf,
                      },
                    });
                  }}
                  required
                  type="checkbox"
                  checked={form.app.widerruf}
                />
                <label htmlFor="widerrufsbelehrung">
                  <p className="custom-inputs-label-p">
                    Ich bin einverstanden, dass der beantragte
                    Versicherungsschutz schon vor Ablauf der Widerrufsfrist
                    gewährt wird, soweit der Versicherungsbeginn bereits vor
                    Ablauf der Widerrufsfrist liegt. Im Fall des Widerrufs wird
                    nur der Beitrag berechnet, der auf die Zeit vor Zugang des
                    Widerrufs entfällt. Der Beitrag wird unverzüglich nach dem
                    vereinbarten Versicherungsbeginn fällig.
                  </p>
                </label>
              </div>
            </div>

            <div className="col-12">
              <hr />
            </div>

            <div className="col-12">
              <h5 className="primary">Versichererinformationen</h5>
              <p className="text-muted">
                Bitte laden sie die Versicherungsinformationen, die
                Produktinformationen, Merkblatt zur vorvertraglichen
                Anzeigepflicht, die Versicherungsbedingungen, das Markblatt
                zur Datenverarbeitung sowie die Informationen zum
                Beratungsversicht herunter.
                <br />
                Bitte bestätigen Sie das Herunterladen der Unterlagen und
                erklären sich durch das Setzen des Häkchen mit deren Inhalt
                einverstanden.
              </p>
              <a
                href={versicherungsInfoUrl()}
                target="_blank"
                onClick={() => {
                  setForm({
                    ...form,
                    app: {
                      ...form.app,
                      versichererinfoDownload: true,
                    },
                  });
                }}
                rel="noopener noreferrer"
                title="Versichererinformationen herunterladen"
                style={{ fontSize: ".9rem" }}
                className={`btn btn-${
                  !form.app.versichererinfoDownload ? "primary" : "default"
                } mb-4`}
              >
                <FontAwesomeIcon
                  icon={
                    !form.app.versichererinfoDownload ? faFilePdf : faCheck
                  }
                  style={{ marginRight: 15 }}
                />
                Versichererinformationen herunterladen
              </a>

              <div className="custom-inputs" key="versicherer_info_accept">
                <input
                  className="form-control"
                  name="versicherer_info_accept"
                  title="Ich verzichte auf den Erhalt der Vermittler- bzw. Versichererinformationen in Papierform."
                  onChange={(e) => {
                    setForm({
                      ...form,
                      app: {
                        ...form.app,
                        versichererinfoAccept: !form.app
                          .versichererinfoAccept,
                      },
                    });
                  }}
                  required
                  disabled={!form.app.versichererinfoDownload}
                  type="checkbox"
                  checked={form.app.versichererinfoAccept}
                />
                <label htmlFor="versicherer_info_accept">
                  <p className="custom-inputs-label-p">
                    Ich verzichte auf den Erhalt der Vermittler- bzw.
                    Versichererinformationen in Papierform. *
                  </p>
                </label>
                {/* <div */}
                {/*   style={{ */}
                {/*     marginTop: -15, */}
                {/*     marginBottom: 10, */}
                {/*   }} */}
                {/* > */}
                {/*   <small> */}
                {/*     Sie können erst Akzeptieren, wenn Sie die Informationen */}
                {/*     zum Widerrufsrecht heruntergeladen. */}
                {/*   </small> */}
                {/* </div> */}
              </div>

              {/* <div className="custom-inputs" key="widerrufsbelehrung"> */}
              {/*   <input */}
              {/*     className="form-control" */}
              {/*     name="widerrufsbelehrung" */}
              {/*     title="Informationen zum Widerrufsrecht" */}
              {/*     onChange={(e) => { */}
              {/*       setForm({ */}
              {/*         ...form, */}
              {/*         app: { */}
              {/*           ...form.app, */}
              {/*           widerruf: !form.app.widerruf, */}
              {/*         }, */}
              {/*       }); */}
              {/*     }} */}
              {/*     required */}
              {/*     type="checkbox" */}
              {/*     checked={form.app.widerruf} */}
              {/*   /> */}
              {/*   <label htmlFor="widerrufsbelehrung"> */}
              {/*     <p className="custom-inputs-label-p"> */}
              {/*       Ich bin einverstanden, dass der beantragte */}
              {/*       Versicherungsschutz schon vor Ablauf der Widerrufsfrist */}
              {/*       gewährt wird, soweit der Versicherungsbeginn bereits vor */}
              {/*       Ablauf der Widerrufsfrist liegt. Im Fall des Widerrufs */}
              {/*       wird nur der Beitrag berechnet, der auf die Zeit vor */}
              {/*       Zugang des Widerrufs entfällt. Der Beitrag wird */}
              {/*       unverzüglich nach dem vereinbarten Versicherungsbeginn */}
              {/*       fällig. */}
              {/*     </p> */}
              {/*   </label> */}
              {/* </div> */}
              <div className="mt-1">
                <small>
                  <b>*</b> Pflichtangabe
                </small>
              </div>
            </div>
          </div>

          <hr />

          <h5 className="primary">Datenübermittlung zur Antragsstelle</h5>
          <p className="text-muted">
            Mit dem Klick auf <b>"Jetzt kostenpflichtig abschließen"</b>{" "}
            bestätige ich, dass der Antrag alle von mir gemachten Angaben
            enthält und bin damit einverstanden, dass der Antrag elektronisch an
            das Versicherungsunternehmen weitergeleitet wird.
          </p>


          {form.vermittlerdaten && (
          <div>
            <hr />
            <div className="row">
              <div className="col-9">
                <h5 className="primary">VM</h5>
              </div>
                <div className="col-3 text-right">
                  <EditButton
                    label="Bearbeiten"
                    iconType="edit"
                    onClick={() => goToStep(1)}
                  />
                </div>
            </div>
              <>
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-6">
                    Vermittlername: <b>{form.vermittler_name}</b>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    Vermittler-Nr.: <b>{form.vermittler_nummer}</b>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12 col-md-6 col-lg-6">
                    Telefon: <b>{form.vermittler_telefon}</b>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    E-Mail: <b>{form.vermittler_email}</b>
                  </div>
                </div>
              </>
          </div>
          )}
        </div>
      </div>

      <FormActions
        currentStep={currentStep}
        enableForwardButton={checkLegal()}
        showFinishButton={true}
        finishAction={finish}
        clear={clear}
        prevStep={prevStep}
      />
    </>
  );
}

export default FifthStep;
