const PROD_URL = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "https://localhost:3000";
const DEV_URL = "http://localhost:3000";
// const DEV_URL = "http://laubenversicherung.voidmod.com";

export const API_URL =
  process.env.NODE_ENV === "production" ? PROD_URL : DEV_URL;

const appendParams = params => {
  let urlParamString = "?";
  Object.entries(params).map(p => (
    urlParamString += `${urlParamString.length > 1 ? "&" : ""}${p[0]}=${p[1]}`
  ));
  return urlParamString;
};

const baseConfig = {};

export default {
  getGesamtBetrag: params =>
    fetch(
      `${API_URL}/get_gesamt_jbetrag${appendParams(params)}`,
      baseConfig
    ).then(resp => resp.json()),

  getSummeGebaeude: params =>
    fetch(
      `${API_URL}/get_vsumme_gebaeude${appendParams(params)}`,
      baseConfig
    ).then(resp => resp.json()),

  getSummeHausrat: params =>
    fetch(
      `${API_URL}/get_vsumme_hausrat${appendParams(params)}`,
      baseConfig
    ).then(resp => resp.json()),

  // get_jbetrag_options
  getOptionsBetrag: params =>
    fetch(
      `${API_URL}/get_jbetrag_options${appendParams(params)}`,
      baseConfig
    ).then(resp => resp.json()),

  getDistricts: params =>
    fetch(`${API_URL}/districts${appendParams(params)}`, baseConfig).then(
      resp => resp.json()
    ),

  getColonies: params =>
    fetch(`${API_URL}/colonies${appendParams(params)}`, baseConfig).then(resp =>
      resp.json()
    ),

  antragCreate: params =>
    fetch(`${API_URL}/antrags${appendParams(params)}`, {
      ...baseConfig,
      method: "POST"
    }).then(resp =>
      resp.json().then(data => ({ status: resp.status, body: data }))
    ),
  // resp
  // 	"iban": "00000000000000000000",
  // "bic": null,
  // "bank_name": null,
  // "error": "IBAN not valid!"
  getBankInfos: params =>
    fetch(`${API_URL}/get_bank_infos${appendParams(params)}`, {
      ...baseConfig
    }).then(resp => resp.json()),
  gisData: params =>
    fetch(`${API_URL}/gis/addresses${appendParams(params)}`, {
      ...baseConfig
    }).then(resp => resp.json())
};
